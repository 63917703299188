export default class Util {
  static parsePath = (path) => {
    let url = new URL(path);
    let pathname = url.pathname;
    if (pathname.endsWith("/")) {
      pathname = pathname.slice(0, -1);
    }
    if (pathname.startsWith("/")) {
      pathname = pathname.substring(1);
    }
    let trailingPath = "";
    let parts = pathname.split("/");
    if (parts.length > 1) {
      trailingPath = parts[parts.length - 1];
    }
    return {
      trailingPath: trailingPath,
      hash: url.hash,
      search: url.search,
    };
  };

  static plural = (cnt, single, plural) => {
    if (cnt > 1) return cnt + " " + plural;
    return cnt + " " + single;
  };

  static getQueryVariable = (variable) => {
    let query = window.location.search.substring(1);
    let vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split("=");
      if (decodeURIComponent(pair[0]) == variable) {
        return decodeURIComponent(pair[1]);
      }
    }
    return null;
  };

  static isMobile = function () {
    /* eslint-disable no-useless-escape */
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
    /* eslint-enable no-useless-escape */
  };

  static calculateAverage = function (scores) {
    let avg = scores.reduce((a, b) => a + b) / scores.length;
    return Math.round(avg * 10) / 10;
  };

  static calculateNpsScore = function (scores) {
    // Expects an array of scores from 0-10
    // Example: nps([10, 9, 10, 4]); -> 50
    // See http://www.medallia.com/net-promoter-score/
    let promoters = 0;
    let detractors = 0;
    let l = scores.length;

    if (!scores || scores.length === 0) {
      return 0;
    }

    for (let i = 0; i < l; i++) {
      if (scores[i] >= 9) promoters++;
      if (scores[i] <= 6) detractors++;
    }

    return Math.max(Math.round((promoters / l - detractors / l) * 100), 0);
  };

  static calculateNpsHistogram = function (scores) {
    // Expects an array of scores from 0-10
    // Example: nps([10, 9, 10, 4]); -> 50
    // See http://www.medallia.com/net-promoter-score/
    let promoters = 0;
    let detractors = 0;
    let neutral = 0;
    let l = scores.length;

    if (!scores || scores.length === 0) {
      return 0;
    }

    for (let i = 0; i < l; i++) {
      if (scores[i] >= 9) promoters++;
      else if (scores[i] <= 6) detractors++;
      else neutral++;
    }

    return {
      total: l,
      promoters: promoters,
      detractors: detractors,
      neutral: neutral,
    };
  };

  static inLocalDevelopment() {
    return window.location.host === "localhost:3000";
  }

  static inProduction() {
    return window.location.host.includes("thecmolist.com");
  }

  static inTestContainer() {
    return process.env.JEST_WORKER_ID !== undefined;
  }

  static validURL = function (str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  };
}
