import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Alert, Container, Col, Row } from "react-bootstrap";
import ActivityIndicator from "../base/ActivityIndicator/ActivityIndicator";
import { Helmet } from "react-helmet";
import Layout from "../base/Layout/Layout";
import Footer from "../base/Footer/Footer";
import ContentDetail from "./ContentDetail";
import Util from "../../util/Util";
import clsx from "clsx";
import "./content.scss";

const Content = ({
  contentLoading,
  fetchContent,
  match,
  getProfileStats,
  getReactionsById,
  content,
  ...rest
}) => {
  const [error, setError] = useState("");
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [contentDataForScroll, setContentDataForScroll] = useState([]);

  useEffect(() => {
    if (
      contentDataForScroll &&
      Object.keys(contentDataForScroll).length > 0 &&
      contentDataForScroll.content_slug
    ) {
      const urlContentSlug = Util.parsePath(window.location.href).trailingPath;
      if (urlContentSlug === contentDataForScroll.content_slug) {
        // scroll to the top
        window.scrollTo(0, 0);
      } else {
        const matchingContent = contentDataForScroll.replies
          .flatMap((r) => [r, ...r.comments.map((c) => c)])
          .find((c) => c.content_slug === urlContentSlug);
        if (matchingContent) {
          const cId = matchingContent.content_id;
          if (document.getElementById(`article-${cId}`)) {
            document.getElementById(`article-${cId}`).scrollIntoView();
          }
        }
      }
      // release the contentDataForScroll object
      setContentDataForScroll([]);
    }
  }, [contentDataForScroll]);

  useEffect(() => {
    const {
      params: { id },
    } = match;
    const fetch = async () => {
      try {
        setError("");
        const contentData = await fetchContent(id);
        setContentDataForScroll(contentData);
      } catch (error) {
        window.location.href = "/404.html";
      }
    };

    fetch();
  }, []);

  useEffect(() => {
    const fetch = async () => await getProfileStats();
    fetch();
  }, []);

  const handleToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  let metaTitle =
    content?.content?.headline?.markdown ||
    content?.content?.headline?.text ||
    "";
  if (metaTitle.length > 0) {
    metaTitle = metaTitle + " - CMOlist";
  }
  return (
    <Layout onToggle={handleToggle}>
      <Container>
        <div className="wrapper">
          {!contentLoading && (
            <Helmet>
              <title>{metaTitle}</title>
              <link rel="canonical" href={window.location.href} />
              <meta
                name="description"
                content={
                  content?.content?.seoText ||
                  content?.content?.description ||
                  content?.content?.articletext?.markdown
                }
              />
            </Helmet>
          )}
          {error && (
            <Row className={clsx("content--error", mobileMenuOpen && "open")}>
              <Col md="12">
                <Alert
                  id="error-section"
                  className="mb-0 mt-2"
                  variant="danger"
                >
                  {error}
                </Alert>
              </Col>
            </Row>
          )}
          <Row
            className={clsx(
              "question-answer-section-wrapper",
              "content--content",
              mobileMenuOpen && "open"
            )}
          >
            {contentLoading ? (
              <Col md="12">
                <ActivityIndicator className="element-center question-activity-indicator" />
              </Col>
            ) : (
              <ContentDetail
                {...rest}
                content={content}
                mobileMenuOpen={mobileMenuOpen}
                setError={setError}
                getReactionsById={getReactionsById}
              />
            )}
          </Row>
          <Row className={clsx("content--footer", mobileMenuOpen && "open")}>
            <Col md="12">
              <Footer />
            </Col>
          </Row>
        </div>
      </Container>
    </Layout>
  );
};

const mapState = (state) => {
  return {
    content: state.contentModel.content,
    contentLoading: state.contentModel.contentLoading,
    reactions: state.reactionModel.reactions,
    reactionsById: state.reactionModel.reactionsById,
    profileStats: state.profileModel.profileStats,
  };
};

const mapDispatch = (dispatch) => {
  return {
    fetchContent: dispatch.contentModel.fetchContent,
    saveCommentToContent: dispatch.contentModel.saveCommentToContent,
    saveCommentToReply: dispatch.contentModel.saveCommentToReply,
    saveReactionToCallerType: dispatch.contentModel.saveReactionToCallerType,
    getProfileStats: dispatch.profileModel.getProfileStats,
    getReactionsById: dispatch.reactionModel.getReactionsById,
  };
};

export default connect(mapState, mapDispatch)(Content);
