import React, { Fragment } from "react";
import CustomCard from "../base/CustomCard/CustomCard";

const RenderLoggedOutUser = () => {
  return (
    <div className="profile-stats__user-info">
      <div className="article-img">
        <a alt="profile" href="/profile">
          <img
            className="element-center"
            src={
              "https://d3k6hg21rt7gsh.cloudfront.net/eyJidWNrZXQiOiJjbW9saXN0aW1hZ2VzIiwia2V5IjoiMTYxNjE4MjEyMTQ0OFNjcmVlbiBTaG90IDIwMjEtMDMtMTkgYXQgMTIuMDkuMzkgUE0ucG5nIiwiZWRpdHMiOnsicmVzaXplIjp7IndpZHRoIjoyMDAsImhlaWdodCI6MjAwLCJmaXQiOiJjb3ZlciJ9fX0="
            }
            alt=""
          />
        </a>
      </div>
      <div className="profile-stats__user-name">
        <a alt="Sign up" href="/signup">
          <span>Join CMOlist</span>
        </a>
      </div>
      <div className="profile-stats__user-company text-center">
        <span>
          The knowledge-sharing platform for the world’s top marketing leaders
          to learn from each other
        </span>
      </div>
    </div>
  );
};
const RenderProfileUser = ({ profileStats }) => {
  return profileStats.profile.isLoggedOut ? (
    <RenderLoggedOutUser />
  ) : (
    <Fragment>
      <div className="profile-stats__user-info">
        <div className="article-img">
          <a alt="profile" href="/profile">
            <img
              className="element-center"
              src={profileStats.profile.image}
              alt=""
            />
          </a>
        </div>
        <div className="profile-stats__user-name">
          <a alt="profile" href="/profile">
            <span>{profileStats.profile.name}</span>
          </a>
        </div>
        <div className="profile-stats__user-company text-center">
          <a alt="profile" href="/profile">
            <span>{profileStats.profile.headline}</span>
          </a>
        </div>
        <div className="profile-stats__edit-button text-center">
          <a alt="View profile" href="/profile">
            Profile
          </a>{" "}
          |{" "}
          <a alt="View My Stack" href="/profile#stack">
            Stack
          </a>{" "}
          |{" "}
          <a alt="View My Expertise" href="/profile#expertise">
            Expertise
          </a>
        </div>
      </div>
      <div className="profile-card-divider" />
      <div className="profile-stats__community">
        <div className="profile-stats__community-title mb-2">
          Community Contribution
        </div>
        <div className="profile-stats__community-table">
          {profileStats.profile.stats.map((stat, idx) => (
            <div className="d-flex justify-content-between mb-2" key={idx}>
              <span>{stat.name}:</span>
              <span className="count">{stat.count}</span>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

const Profile = ({ profileStats }) => {
  return (
    <CustomCard>
      <div className="profile-stats__user">
        {profileStats.profile && (
          <RenderProfileUser profileStats={profileStats} />
        )}
      </div>
    </CustomCard>
  );
};

export default Profile;
