import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import Markdown from "markdown-to-jsx";
import CustomCard from "../CustomCard/CustomCard";
import Avatar from "../Avatar/Avatar";
import "./FeaturedContent.scss";

const FeaturedContent = (props) => {
  const { featuredContent } = props;

  return (
    <div
      className={clsx(
        "featured-content",
        featuredContent && featuredContent.length > 1 && "multi-content"
      )}
    >
      {featuredContent.map((content, i) => (
        <CustomCard heading={content.title} key={i} seeAllLink={"/guides"}>
          {content.contentList &&
            content.contentList.map((item, index) => (
              <div className="featured-content-list" key={i + "-" + index}>
                <div className="featured-content-list-item">
                  <img
                    className="featured-content-list-item__image"
                    src={item.image}
                    alt="content image"
                  ></img>
                  <div>
                    <a
                      href={item.link}
                      className="featured-content-list-item__wrapper"
                    >
                      <h6 className="featured-content-list-item__title">
                        {item.title}
                      </h6>
                    </a>

                    <p className="featured-content-list-item__subtitle">
                      {item.subtitle}
                    </p>
                    <div className="featured-content-list-item__markdown">
                      <Markdown>{item.subheading.markdown}</Markdown>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </CustomCard>
      ))}
    </div>
  );
};

export default FeaturedContent;
